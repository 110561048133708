import type { NavigationMenuItem } from '@revolutionprep/types'
import type { RuntimeConfig } from 'nuxt/schema'

const menuItems = (config: RuntimeConfig): NavigationMenuItem[] => {
  return [
    {
      id: 'navItemDashboard',
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      to: '/',
      external: false
    },
    {
      id: 'navItemInbox',
      title: 'Inbox',
      icon: 'mdi-inbox',
      to: `${config.public.adminLegacyUrl}/inbox`,
      external: true,
      target: '_blank',
      roles: [
        'academic_advisor',
        'outreach_specialist',
        'learning_success_manager',
        'advising_manager',
        'director_of_academic_advising',
        'developer'
      ]
    },
    {
      id: 'navItemCallList',
      title: 'Call List',
      icon: 'mdi-card-account-phone',
      to: `${config.public.adminLegacyUrl}/call-list`,
      external: true,
      target: '_blank',
      roles: [
        'academic_advisor',
        'outreach_specialist',
        'learning_success_manager',
        'advising_manager',
        'director_of_academic_advising',
        'developer'
      ]
    },
    {
      id: 'navItemProspecting',
      title: 'Prospecting',
      icon: 'mdi-account-cash',
      to: `${config.public.adminLegacyUrl}/family-search`,
      external: true,
      target: '_blank',
      roles: [
        'academic_advisor',
        'outreach_specialist',
        'learning_success_manager',
        'advising_manager',
        'director_of_academic_advising',
        'developer'
      ]
    },
    {
      id: 'navItemAdvisor',
      title: 'Advisor',
      icon: 'mdi-briefcase',
      to: null,
      roles: [
        'academic_advisor',
        'outreach_specialist',
        'learning_success_manager',
        'advising_manager',
        'director_of_academic_advising',
        'developer',
        'admin'
      ],
      items: [
        {
          id: 'navItemAdvisorRegions',
          title: 'Regions',
          icon: null,
          to: `${config.public.adminLegacyUrl}/regions`,
          external: true,
          target: '_blank',
          roles: [
            'advising_manager',
            'closer',
            'director_of_academic_advising',
            'operations',
            'regional_event_coordinator',
            'developer'
          ]
        },
        {
          id: 'navItemAdvisorLeadSources',
          title: 'Lead Sources',
          icon: null,
          to: `${config.public.adminLegacyUrl}/lead-sources`,
          external: true,
          target: '_blank',
          roles: 'admin'
        },
        {
          id: 'navItemAdvisorTutorSearch',
          title: 'Tutor Search',
          icon: null,
          to: `${config.public.adminLegacyUrl}/tutors`,
          external: true,
          target: '_blank',
          roles: [
            'academic_advisor',
            'outreach_specialist',
            'learning_success_manager',
            'advising_manager',
            'director_of_academic_advising',
            'developer'
          ]
        },
        {
          id: 'navItemAdvisorObReport',
          title: 'OB Report',
          icon: null,
          to: `${config.public.adminLegacyUrl}/reports/balances`,
          external: true,
          target: '_blank',
          roles: [
            'academic_advisor',
            'outreach_specialist',
            'learning_success_manager',
            'advising_manager',
            'director_of_academic_advising',
            'developer'
          ]
        },
        {
          id: 'navItemAdvisorEmailCampaigns',
          title: 'Email Campaigns',
          icon: null,
          to: `${config.public.adminLegacyUrl}/email-campaigns`,
          external: true,
          target: '_blank',
          roles: [
            'closer',
            'developer',
            'operations'
          ]
        }
      ]
    },
    {
      id: 'navItemTeams',
      title: 'Teams',
      icon: 'mdi-account-group',
      to: null,
      items: [
        {
          id: 'navItemTeamsAdvisingTeams',
          title: 'Advising Teams',
          icon: null,
          to: `${config.public.adminLegacyUrl}/advisor-teams`,
          external: true
        },
        {
          id: 'navItemTeamsTutorTeams',
          title: 'Tutor Teams',
          icon: null,
          to: `${config.public.adminLegacyUrl}/tutor-teams`,
          external: true,
          target: '_blank',
          roles: [
            'instruction_manager',
            'director_of_instruction_management'
          ]
        }
      ]
    },
    {
      id: 'navItemUsers',
      title: 'Users',
      icon: 'mdi-account',
      to: null,
      items: [
        {
          id: 'navItemUsersStudents',
          title: 'Students',
          icon: null,
          to: `${config.public.adminLegacyUrl}/users/students`,
          external: true
        },
        {
          id: 'navItemUsersParents',
          title: 'Parents',
          icon: null,
          to: `${config.public.adminLegacyUrl}/users/parents`,
          external: true
        },
        {
          id: 'navItemUsersTutors',
          title: 'Tutors',
          icon: null,
          to: `${config.public.adminLegacyUrl}/users/tutors`,
          external: true,
          target: '_blank',
          roles: 'hr'
        },
        {
          id: 'navItemUsersEmployees',
          title: 'Employees',
          icon: null,
          to: `${config.public.adminLegacyUrl}/users/employees`,
          external: true,
          target: '_blank',
          roles: 'hr'
        }
      ]
    },
    {
      id: 'navItemCourses',
      title: 'Courses',
      icon: 'mdi-school',
      to: null,
      items: [
        {
          id: 'navItemCoursesCourseSearch',
          title: 'Course Search',
          icon: null,
          to: '/courses',
          external: false
        },
        {
          id: 'navItemCoursesSessionSearch',
          title: 'Session Search',
          icon: null,
          to: `${config.public.adminLegacyUrl}/sessions`,
          external: true,
          target: '_blank',
          roles: [
            'instruction_manager',
            'regional_event_coordinator',
            'advising_manager',
            'partnership_manager'
          ]
        },
        {
          id: 'navItemCoursesDepartments',
          title: 'Departments',
          icon: null,
          to: `${config.public.adminLegacyUrl}/departments`,
          external: true,
          target: '_blank',
          roles: 'developer'
        },
        {
          id: 'navItemCoursesTestDates',
          title: 'Test Dates',
          icon: null,
          to: `${config.public.adminLegacyUrl}/test-dates`,
          external: true,
          target: '_blank',
          roles: 'instruction_manager'
        }
      ]
    },
    {
      id: 'navItemReports',
      title: 'Reports',
      icon: 'mdi-chart-line',
      to: null,
      roles: 'admin',
      items: [
        {
          id: 'navItemReportsTransactionsReport',
          title: 'Transactions Report',
          icon: null,
          to: `${config.public.adminLegacyUrl}/reports/transactions`,
          external: true,
          roles: [
            'advising_manager',
            'advisor',
            'closer',
            'operations',
            'outreach_specialist'
          ]
        },
        {
          id: 'navItemReportsDailySalesReport',
          title: 'Daily Sales Report',
          icon: null,
          to: `${config.public.adminLegacyUrl}/reports/daily-sales`,
          external: true
        },
        {
          id: 'navItemReportsAdvisorActivityReport',
          title: 'Advisor Activity Report',
          icon: null,
          to: `${config.public.adminLegacyUrl}/reports/advisor-activity`,
          external: true
        }
      ]
    },
    {
      id: 'navItemOperations',
      title: 'Operations',
      icon: 'mdi-cog',
      to: null,
      roles: [
        'accountant',
        'director_of_finance_and_analytics',
        'hr',
        'operations'
      ],
      items: [
        {
          id: 'navItemOperationsAffiliateReservations',
          title: 'Affiliate Reservations',
          icon: 'mdi-barcode',
          to: `${config.public.adminLegacyUrl}/affiliate-reservations`,
          external: true,
          target: '_blank',
          roles: ['accountant', 'operations']
        },
        {
          id: 'navItemOperationsAffiliateInvoices',
          title: 'Affiliate Invoices',
          icon: 'mdi-view-list',
          to: `${config.public.adminLegacyUrl}/affiliate-invoices`,
          external: true,
          target: '_blank',
          roles: ['accountant', 'operations']
        },
        {
          id: 'navItemOperationsAffiliateInvoiceItems',
          title: 'Affiliate Invoice Items',
          icon: 'mdi-format-list-bulleted',
          to: `${config.public.adminLegacyUrl}/affiliate-invoice-items`,
          external: true,
          target: '_blank',
          roles: ['accountant', 'operations']
        },
        {
          id: 'navItemOperationsBookingUpdate',
          title: 'Booking Update',
          icon: 'mdi-currency-usd',
          to: `${config.public.adminLegacyUrl}/bookings`,
          external: true,
          target: '_blank',
          roles: 'operations'
        },
        {
          id: 'navItemOperationsCoupons',
          title: 'Coupons',
          icon: 'mdi-content-cut',
          to: `${config.public.adminLegacyUrl}/coupons`,
          external: true,
          target: '_blank',
          roles: 'operations'
        },
        {
          id: 'navItemOperationsMaterials',
          title: 'Materials',
          icon: 'mdi-notebook',
          to: `${config.public.adminLegacyUrl}/materials`,
          external: true,
          target: '_blank',
          roles: 'operations'
        },
        {
          id: 'navItemOperationsOutstandingBalances',
          title: 'Outstanding Balances',
          icon: 'mdi-alert',
          to: `${config.public.adminLegacyUrl}/reports/balances`,
          external: true,
          target: '_blank'
        },
        {
          id: 'navItemOperationsPayrolls',
          title: 'Payrolls',
          icon: 'mdi-currency-usd',
          to: `${config.public.adminLegacyUrl}/payrolls`,
          external: true,
          target: '_blank',
          roles: [
            'accountant',
            'director_of_finance_and_analytics',
            'hr'
          ]
        },
        {
          id: 'navItemOperationsRefunds',
          title: 'Refunds',
          icon: 'mdi-cash-refund',
          to: `${config.public.adminLegacyUrl}/refund-requests`,
          external: true,
          target: '_blank',
          roles: [
            'operations',
            'director_of_finance_and_analytics'
          ]
        },
        {
          id: 'navItemOperationsShipments',
          title: 'Shipments',
          icon: 'mdi-truck',
          to: `${config.public.adminLegacyUrl}/shipments`,
          external: true,
          target: '_blank',
          roles: 'operations'
        },
        {
          id: 'navItemOperationsTutorMatches',
          title: 'Tutor Matches',
          icon: 'mdi-brush',
          to: `${config.public.adminLegacyUrl}/tutor-matches`,
          external: true,
          target: '_blank',
          roles: 'operations'
        }
      ]
    },
    {
      id: 'navItemEssays',
      title: 'Essays',
      icon: 'mdi-file-document',
      to: null,
      roles: 'instruction_manager',
      items: [
        {
          id: 'navItemEssaysEssayGraders',
          title: 'Essay Graders',
          icon: null,
          to: `${config.public.adminLegacyUrl}/essays`,
          external: true,
          target: '_blank',
          roles: 'instruction_manager'
        },
        {
          id: 'navItemEssaysEssayGrading',
          title: 'Essay Grading',
          icon: null,
          to: `${config.public.adminLegacyUrl}/essay-grading`,
          external: true,
          target: '_blank'
        }
      ]
    }
  ]
}

export default menuItems
